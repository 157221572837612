import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {login, logout} from '../reducers/session';
import {getUserSession} from './http';
import {getToken} from './auth';
import {getHybridUser, isHybrid} from './hybrid';
const SessionHOC = function (WrappedComponent) {
    class SessionComponent extends React.Component {
        componentDidMount () {
            // 如果是混合应用
            if (isHybrid()){
                const user = getHybridUser();
                if (user) {
                    this.props.login(user.scratch);
                }
                return () => {};
            }

            const token = getToken();
            if (!token) {
                return () => {};
            }
            getUserSession(token)
                .then(data => {
                    this.props.login(data.scratch);
                })
                .catch(err => {
                    console.error(err);
                });
        }

      
        render () {
            const {...componentProps} = this.props;
            return <WrappedComponent {...componentProps} />;
        }
    }
    SessionComponent.propTypes = {
        login: PropTypes.func,
        logout: PropTypes.func
    };

    const mapStateToProps = (state, ownProps) => ({});

    const mapDispatchToProps = (dispatch, ownProps) => ({
        login: user => {
            dispatch(login(user));
        },
        logout: () => {
            dispatch(logout());
        }
    });

    return connect(mapStateToProps, mapDispatchToProps)(SessionComponent);
};

export default SessionHOC;
